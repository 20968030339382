html {
  font-family: Arial, Helvetica, sans-serif; }

body {
  padding: 2em; }

.main-container {
  margin: auto; }

.main-navigation {
  margin-bottom: 0 !important; }

.network-loading {
  height: 95vh;
  margin-top: 0 !important; }

.network-header {
  text-align: center; }

.graph-container {
  padding: 0 !important; }

.segment.pushable-sidebar {
  margin-top: 0 !important; }

.user-menu p {
  margin: 0 !important; }

.user-menu .user-sign-out {
  cursor: pointer;
  padding-left: 1em; }
  .user-menu .user-sign-out .sign-out-icon {
    margin-left: .5em; }

.not-found {
  text-align: center;
  font-size: 1.5rem;
  padding-top: 1em; }
  .not-found .pathname {
    color: green; }
